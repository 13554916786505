import { SetStateAction, useState } from "react";
import Papa from "papaparse";

function PhotoGrabber() {
    const [data, setData] = useState([]);
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vTcUPXfd9asjNfX92botDx3nP-6dBOcuJOdvcBwSuZdGn3yOE2SKVehtM-XM2xfKsBGesp7NdQvNoXp/pub?gid=414693743&single=true&output=csv", {
          download: true,
          header: true,
          complete: (results: { data: SetStateAction<never[]>; }) => {
              setData(results.data);
          },
      });
  
      const photos = data.map((photo: { URI: string | undefined; }) => {
        return <img src={photo.URI} alt="ian" />
    });
    return (
       <div>
            <li>
               {photos} 
            </li>
       </div>
    );
  }
  
  export default PhotoGrabber;